
<template>
  <div>
    <h5 class="pt-2"></h5>
    <div id="laporan-app" class="border border-solid d-theme-border-grey-light rounded relative overflow-hidden">
      <vs-sidebar class="items-no-padding" parent="#laporan-app" :click-not-close="clickNotClose" :hidden-background="clickNotClose" v-model="isSidebarFilterActive">
          <component :is="scrollbarTag" class="laporan-scroll-area" :settings="settings" :key="$vs.rtl">
              <laporan-filters @closeSidebar="toggleFilterSidebar"
              :laporanFilter="laporanFilter"
              :Search="search"
              :dateMulai="mulai"
              :dateSampai="sampai"></laporan-filters>
          </component>
      </vs-sidebar>

      <div :class="{'sidebar-spacer': clickNotClose}"  id="laporan-load" class="no-scroll-content border border-r-0 border-b-0 border-t-0 border-solid d-theme-border-grey-light no-scroll-content vs-con-loading__container">

        <div class="d-theme-dark-bg items-center border border-l-0 border-r-0 border-t-0 border-solid d-theme-border-grey-light flex items-center">
          
          <!-- TOGGLE SIDEBAR BUTTON -->
          <feather-icon class="md:inline-flex lg:hidden ml-2 cursor-pointer" icon="MenuIcon" @click.stop="toggleFilterSidebar(true)" />

          <!-- SEARCH BAR -->
          <vs-input icon-no-border size="large" placeholder="Search..." v-model="search" v-on:keyup.enter="filterData()" class="vs-input-no-border vs-input-no-shdow-focus w-full" />
          
          <vs-button class="float-right mr-2" size="small" icon-pack="feather" icon="icon-search" @click.stop="filterData()"></vs-button>
      </div>

      <div class="flex items-center flex-wrap justify-end py-2 md:px-8 px-4">
        <div class="dropdown-button-container">
          <vs-button class="btnx" type="border" size="small">Page</vs-button>

          <vs-dropdown>
            <vs-button class="btn-drop" type="filled" size="small">{{laporanCurrentPage}}</vs-button>
            <vs-dropdown-menu>
              <vs-dropdown-item v-for="p in laporanPage" :key="p" @click.stop="nextData(p)" :disabled="p === laporanCurrentPage">
                {{ p }}
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
        <!--
        <div class="flex">
          <flat-pickr format="yyyy-MM-dd" :config="configFromdateTimePicker" v-model="mulai" placeholder="Mulai" @on-change="onFromChange" class="mr-4" style="width:100px;height: 32px;"/>
          <flat-pickr format="yyyy-MM-dd" :config="configTodateTimePicker" v-model="sampai" placeholder="Sampai" @on-change="onToChange" class="mr-2" style="width:100px;height: 32px;"/>
          <feather-icon @click="filterData" icon="ArrowRightIcon" class="cursor-pointer" svg-classes="h-7 w-7"></feather-icon>
        </div>
        <div class="flex items-center">
          <div class="dropdown-button-container">
            <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
              <feather-icon icon="CodeIcon" class="cursor-pointer" svg-classes="h-7 w-7"></feather-icon>
              <vs-dropdown-menu>
                <vs-dropdown-item v-for="p in laporanPage" :key="p" @click.stop="nextData(p)" :disabled="p === laporanCurrentPage">
                  {{ p }}
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>


          <feather-icon icon="PlusIcon" class="cursor-pointer ml-5" svg-classes="h-7 w-7" @click="addUpdateSidebar()"></feather-icon>
        </div>
        -->
      </div>

      <!-- LAPORAN LIST -->
      <component :is="scrollbarTag" id="laporan-load" class="laporan-content-scroll-area" :settings="settings" ref="laporanListPS" :key="$vs.rtl">
          <transition-group name="list-enter-up" tag="ul" appear>
            <li
              class="cursor-pointer"
              v-for="(laporan, index) in laporanList"
              :key="index"
              :style="[{transitionDelay: (index * 0.1) + 's'}]"
              @click="viewUpdateSidebar(laporan)">
              <laporan-item 
                :data="laporan" 
              />
            </li>
          </transition-group>
      </component>
      <!-- /LAPORAN LIST -->
    </div>

    <laporan-update 
      :isUpdateActive="isUpdateActive"
      @closeUpdate="closeUpdateSidebar"
      :data="dataLaporan">
    </laporan-update>
    </div>
  </div>
</template>

<script>
import moduleLaporan          from '@/stores/laporan/moduleLaporan.js'
import LaporanItem            from './LaporanItem.vue'
import LaporanFilters         from './LaporanFilters.vue'
import LaporanUpdate          from './LaporanUpdate.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import flatPickr          from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
  components: {
    LaporanItem,
    LaporanFilters,
    LaporanUpdate,
    VuePerfectScrollbar,
    flatPickr
  },
  data () {
    return {
      dataLaporan       : {},
      isAccountAddActive   : false,
      currFilter           : '',
      clickNotClose        : true,
      displayPrompt        : false,
      isSidebarFilterActive: true,

      isUpdateActive       : false,

      settings : {
        maxScrollbarLength : 60,
        wheelSpeed         : 0.30
      },

      configFromdateTimePicker: {
        minDate: null,
        maxDate: null
      },
      configTodateTimePicker: {
        minDate: null
      },
      changeMulai: false,

      // Param
      search: '',
      mulai: null,
      sampai: null,
    }
  },
  watch: {
    isAccountAddActive (value) {
      if (!value) this.showThread = false
    },
    isUpdateActive (value) {
      if (!value) this.showThread = false
    },
    laporanFilter () {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#laporan-load',
        type: 'sound',
        scale: 0.5
      })
      const scroll_el = this.$refs.laporanListPS.$el || this.$refs.laporanListPS
      scroll_el.scrollTop = 0
      const obj = {
        type: 'laporan',
        filter_type: this.$route.params.filter_type,
        filter: this.$route.params.filter,
        urutan: 'desc',
        jumlah: 10,
        search: this.search,
        dateMulai: this.mulai,
        dateSampai: this.sampai
      }
      this.$store.commit('laporan/SET_NULL_TASKS')
      this.$store.dispatch('laporan/fetchLaporan', obj)
      if (this.$store.state.laporan.laporans.length > 0 || this.$store.state.laporan.laporans.length === 0) {
        setTimeout(() => {
          this.$vs.loading.close('#laporan-load > .con-vs-loading')
        }, 2000)
      }
      this.toggleFilterSidebar()
    },
    windowWidth () {
      this.setSidebarWidth()
    }
  },
  computed: {
    laporanFilter ()   { return this.$route.params.filter },
    laporanList ()     { return this.$store.state.laporan.laporans },
    laporanTags () {
      return this.$store.state.laporan.Tags
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag },
    windowWidth ()  { return this.$store.state.windowWidth },
    laporanTotal () {
      return this.$store.state.laporan.total
    },
    laporanTotalSatu () {
      return this.$store.state.laporan.total + 1
    },
    laporanLast () {
      return this.$store.state.laporan.last
    },
    laporanPerPage () {
      return this.$store.state.laporan.per_page
    },
    laporanCurrentPage () {
      return this.$store.state.laporan.current_page
    },
    laporanPage () {
      const page = []
      const number = this.$store.state.laporan.total / this.$store.state.laporan.per_page
      var i
      for (i=0; i < number; i++) {
        page.push(i+1)
      }
      return page
    },
    laporanTotalPage () {
      let number = this.$store.state.laporan.total / this.$store.state.laporan.per_page
      if (number < 1) {
        number = 1
      }
      return number.toFixed(0)
    }
  },
  created () {
    this.$vs.loading()
    this.$store.registerModule('laporan', moduleLaporan)
    this.setSidebarWidth()

    const obj = {
      type: 'laporan',
      filter_type: 'laporan',
      filter: this.$route.params.filter,
      urutan:'desc',
      jumlah: 10
    }
    // Fetch
    this.$store.dispatch('laporan/fetchLaporan', obj)
    this.$store.dispatch('laporan/fetchKategori')
    if (this.$store.state.laporan.laporans.length > 0 || this.$store.state.laporan.laporans.length === 0) {
      setTimeout(() => {
        this.$vs.loading.close()
      }, 2000)
    }
  },
  beforeUpdate () {
    this.currFilter = this.$route.params.filter
  },
  beforeDestroy () {
    this.$store.unregisterModule('laporan')
  },
  mounted () { 
  },
  methods: {
    onFromChange(selectedDates, dateStr) {
      this.changeMulai = true
      this.$set(this.configTodateTimePicker, 'minDate', dateStr)
    },
    onToChange(selectedDates, dateStr) {
      if (this.changeMulai != false) {
        this.$set(this.configFromdateTimePicker, 'maxDate', dateStr)
      } else {
        this.$vs.notify({
          title: 'Error',
          text: 'Pilih tanggal mulai',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
      
    },
    setSidebarWidth () {
      if (this.windowWidth < 992) {
        this.isSidebarFilterActive = this.clickNotClose = false
      } else {
        this.isSidebarFilterActive = this.clickNotClose = true
      }
    },
    toggleFilterSidebar (value = false) {
      if (!value && this.clickNotClose) return
      this.isSidebarFilterActive = value
    },
    addUpdateSidebar() {
      this.dataLaporan = {}
      this.isUpdateActive = true
    },
    viewUpdateSidebar(data) {
      this.dataLaporan = data
      this.isUpdateActive = true
    },
    closeUpdateSidebar () {
      this.isUpdateActive = false
    },
    nextData (page) {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#laporan-load',
        type: 'sound',
        scale: 0.5
      })
      const scroll_el = this.$refs.laporanListPS.$el || this.$refs.laporanListPS
      scroll_el.scrollTop = 0
      
      const obj = {
        type: 'laporan',
        filter_type: this.$route.params.filter_type,
        filter: this.$route.params.filter,
        urutan: 'desc',
        jumlah: 10,
        search: this.search,
        dateMulai: this.mulai,
        dateSampai: this.sampai,
        page: page
      }
      this.$store.commit('laporan/SET_NULL_TASKS')
      this.$store.dispatch('laporan/fetchLaporan', obj)
      if (this.$store.state.laporan.laporans.length > 0 || this.$store.state.laporan.laporans.length === 0) {
        setTimeout(() => {
          this.$vs.loading.close('#laporan-load > .con-vs-loading')
        }, 2000)
      }
    },
    filterData () {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#laporan-load',
        type: 'sound',
        scale: 0.5
      })
      const scroll_el = this.$refs.laporanListPS.$el || this.$refs.laporanListPS
      scroll_el.scrollTop = 0

      this.$store.commit('laporan/', this.$route.params.filter)
      const obj = {
        type: 'laporan',
        filter_type: this.$route.params.filter_type,
        filter: this.$route.params.filter,
        urutan: 'desc',
        jumlah: 10,
        search: this.search,
        dateMulai: this.mulai,
        dateSampai: this.sampai
      }

      this.$store.commit('laporan/SET_NULL_TASKS')
      this.$store.dispatch('laporan/fetchLaporan', obj)
      if (this.$store.state.laporan.laporans.length > 0 || this.$store.state.laporan.laporans.length === 0) {
        setTimeout(() => {
          this.$vs.loading.close('#laporan-load > .con-vs-loading')
        }, 2000)
      }
    }
  }
}

</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/laporan.scss";
.dropdown-button-container {
  display: flex;
  align-items: center;

  .btnx {
    border-radius: 5px 0px 0px 5px;
  }

  .btn-drop {
    border-radius: 0px 5px 5px 0px;
    border-left: 1px solid rgba(255, 255, 255, .2);
  }
  .vs-button {
    padding: 0.5rem 1.5rem !important;
  }
}
.total-slider {
  min-width: unset;
}
</style>
