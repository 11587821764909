
export default {
  laporans: [],
  laporanTags: [],
  laporanFilter: null,
  laporanKategoris: [],
  laporanAlbums: [],
  total: null,
  last: null,
  per_page: null,
  current_page: null
}
