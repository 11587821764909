
<template>
    <div class="laporan__filters-container">
      
        <div class="px-6 pt-6">
            <router-link tag="span" class="flex cursor-pointer" :class="{'text-primary': laporanFilter == 'all'}" :to="`${baseUrl}/filter/all`">
                <feather-icon icon="LayersIcon" :svgClasses="[{'text-primary stroke-current': laporanFilter == 'all'}, 'h-6 w-6']"></feather-icon>
                <span class="ml-3">All</span>
            </router-link>
        </div>

        <vs-divider></vs-divider>
        
        <div class="px-6 py-4">
            <div class="flex items-center flex-wrap justify-between">
                <div class="flex items-center">
                  <h5>Kategori</h5>
                </div>
            </div>
            <div v-for="(kategori, index) in laporanKategoris" :key="index">
                <router-link tag="span" class="flex mt-6 cursor-pointer" :to="`${baseUrl}/laporan/${kategori.slug}`">
                    <span class="" :class="{'text-primary': laporanFilter == kategori.slug}">{{ kategori.name }}</span>
                </router-link>
            </div>
        </div>

        <vs-divider></vs-divider>
        
        <div class="px-6 py-4">
          <router-link tag="span" class="flex cursor-pointer" :class="{'text-primary': laporanFilter == 'trash'}" :to="`${baseUrl}/filter/trash`">
              <feather-icon icon="TrashIcon" :svgClasses="[{'text-primary stroke-current': laporanFilter == 'trash'}, 'h-6 w-6']"></feather-icon>
              <span class="text-lg ml-3">Trash</span>
          </router-link>
        </div>
    </div>
</template>
  
<script>

export default{
  components: {
  },
  props: {
    laporanFilter: {
      type: String,
      //required: true
    },
    dateMulai: {
      type: String,
    },
    dateSampai: {
      type: String,
    },
    Search: {
      type: String,
    }
  },
  data () {
    return {
      enableCross: false,
      tooltipMerge: false,
      formatter: value => `￥${value}`,

      activePromptTags: false,
      dataTag: null,
      dataColor: 'pilih',
      dataColorx: ['primary', 'success', 'danger', 'warning', 'dark']
    }
  },
  computed: {
    validateTag () {
      return !this.errors.any() && this.dataTag && this.dataColor != 'pilih'
    },
    laporanTags () {
      return this.$store.state.laporan.laporanTags
    },
    laporanKategoris () {
      return this.$store.state.laporan.laporanKategoris
    },
    taxonomy () {
      return 'tag'
    },
    /*
    laporanFilter () {
      return this.$route.params.filter
    },
    */
    baseUrl () {
      const path = this.$route.path
      const paths = path.slice(0, path.lastIndexOf('/'))
      return paths.slice(0, paths.lastIndexOf('/'))
    }
  },
  methods: {
    clearTag () {
      this.dataTag = null
      this.dataColor = 'pilih'
    },
  },
  created () {
  }
}
</script>
