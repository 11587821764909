<template>
  <div>
    <vs-sidebar click-not-close parent="#laporan-app" :hidden-background="true" class="full-vs-sidebar laporan-view-sidebar items-no-padding" v-model="isUpdateActive" position-right id="laporan-update">

      <div class="flex flex-wrap items-center justify-between md:px-8 px-6 py-2 d-theme-dark-bg laporan-header bg-primary">
        <div class="flex">
          <feather-icon :icon="$vs.rtl ? 'ArrowRightIcon' : 'ArrowLeftIcon'" @click="closeUpdateView" class="cursor-pointer mr-4" svg-classes="text-white w-6 h-6"></feather-icon>
          <h5 class="text-white pt-1">{{ Object.entries(this.data).length === 0 ? "New" : "View" }} Laporan</h5>
        </div>

        <!--
        <div class="flex items-center">
          <div v-if="Object.entries(this.data).length != 0">
            <div class="flex items-center" v-if="filter != 'trash'">
              <vs-switch class="mr-2 pt-1" color="dark" v-model="disabled" v-can="'E'"/>
              <vs-button class="" color="danger" size="small" icon-pack="feather" icon="icon-trash" @click="hapusLaporanConfirm()" v-can="'D'">Trash</vs-button>
            </div>
            <div class="flex" v-else>
              <vs-button class="" color="warning" size="small" icon-pack="feather" icon="icon-refresh-ccw" @click="restoreLaporanConfirm()" v-can="'D'">Restore</vs-button>
            </div>
          </div>
        </div>
        -->

        <div class="flex items-center">
          <vs-button class="" color="secondary" size="small" icon-pack="feather" icon="icon-save" :disabled="!isFormValid" @click="submitData" v-can="'E'">Save</vs-button>
        </div>
        
      </div>
      
      <component class="laporan-content-scroll-area vx-col w-full px-5 mt-5 pb-base" :settings="settings" :is="scrollbarTag" key="1">

        <vs-divider>Pelapor</vs-divider>
        <template v-if="dataAuthor">
          <div class="flex">
            <vs-input
              disabled
              v-model="dataAuthor.nama"
              class="w-1/2 pr-5" 
            />

            <vs-input
              disabled
              v-model="dataAuthor.telepon"
              class="w-1/2" 
            />
          </div>

          <div class="flex items-center">
            <vs-input
              disabled
              v-model="dataAuthor.email"
              class="w-1/2 pr-5" 
            />
            <vs-select
            label="Hubungan Dengan Korban"
            v-model="dataPelaporHubunganKorban"
            v-validate="'required'"
            class="w-1/2"
            @change="pilihPelaporHubunganKorban()"
            @click.prevent="changePelaporHubunganKorban()"
            >
               <vs-select-item value="0" text="Pilih"/>
               <vs-select-item :key="itemx.id" :value="itemx.id" :text="itemx.name" v-for="itemx in listHubunganKorban"/>
            </vs-select>
          </div>
        </template>

        <vs-divider>Kasus Yang Diadukan</vs-divider>

          <div class="flex mb-5">
              <vs-select
              label="Jenis Kasus"
              v-model="dataLaporanJenis"
              v-validate="'required'"
              class="w-1/2 pr-5"
              @change="pilihLaporanJenis()"
              @click.prevent="changeLaporanJenis()"
              >
                <vs-select-item value="0" text="Pilih"/>
                <vs-select-item :key="itemx.taxonomy_id" :value="itemx.id" :text="itemx.name" v-for="itemx in listLaporanJenis"/>
              </vs-select>

              <vs-select
              label="Bentuk Kekerasan"
              v-model="dataKekerasanBentuk"
              v-validate="'required'"
              class="w-1/2 pr-5"
              @change="pilihKekerasanBentuk()"
              @click.prevent="changeKekerasanBentuk()"
              >
                <vs-select-item value="0" text="Pilih"/>
                <vs-select-item :key="itemx.id" :value="itemx.id" :text="itemx.name" v-for="itemx in listKekerasanBentuk"/>
              </vs-select>

              <vs-select
              label="Modus Kekerasan"
              v-model="dataKekerasanModus"
              v-validate="'required'"
              class="w-1/2"
              @change="pilihKekerasanModus()"
              @click.prevent="changeKekerasanModus()"
              >
                <vs-select-item value="0" text="Pilih"/>
                <vs-select-item :key="itemx.id" :value="itemx.id" :text="itemx.name" v-for="itemx in listKekerasanModus"/>
              </vs-select>
          </div>
          
          <div class="flex mb-5">
            <vs-select
            label="Tempat Kejadian"
            v-model="dataKejadianTempat"
            v-validate="'required'"
            class="w-1/5 pr-5"
            @change="pilihKejadianTempat()"
            @click.prevent="changeKejadianTempat()"
            >
              <vs-select-item value="0" text="Pilih"/>
              <vs-select-item :key="itemx.id" :value="itemx.id" :text="itemx.name" v-for="itemx in listKejadianTempat"/>
            </vs-select>
            
            <div>
              <small>Tanggal Kejadian</small>
              <datepicker :language="languages[language]" format="dd MMMM yyyy" class="w-full pr-5" v-model="dataKejadianTanggal"></datepicker>
            </div>

            <div v-if="dataPernahDilaporkan">
              <span>Apakah sudah pernah dilaporkan ?</span>
              <ul class="flex mt-1">
                  <li class="mr-base">
                    <vs-radio color="success" v-model="dataPernahDilaporkan" vs-value="Ya"  vs-name="Pernah">Ya</vs-radio>
                  </li>
                  <li>
                    <vs-radio color="danger" v-model="dataPernahDilaporkan" vs-value="Tidak"  vs-name="Pernah">Tidak</vs-radio>
                  </li>
              </ul>
            </div>

            <template v-if="dataPernahDilaporkan === 'Ya'">
              <vs-select
              label="Tempat Lapor"
              v-model="dataTempatLapor"
              v-validate="'required'"
              class="w-1/4 pl-base"
              @change="pilihTempatLapor()"
              @click.prevent="changeTempatLapor()"
              >
                  <vs-select-item value="0" text="Pilih"/>
                  <vs-select-item :key="itemx.id" :value="itemx.id" :text="itemx.name" v-for="itemx in listTempatLapor"/>
              </vs-select>
            </template>
          </div>

          <div class="flex mb-5">
          </div>

          <div class="flex mb-5">
            <vs-select
              label="Layanan Dibutuhkan"
              v-model="dataLayananDibutuhkan"
              v-validate="'required'"
              class="w-1/3 pr-5"
              @change="pilihLayananDibutuhkan()"
              @click.prevent="changeLayananDibutuhkan()"
              >
                <vs-select-item value="0" text="Pilih"/>
                <vs-select-item :key="itemx.id" :value="itemx.id" :text="itemx.name" v-for="itemx in listLayananDibutuhkan"/>
            </vs-select>

            <vs-textarea class="bg-white w-3/4" label="Kronologi" v-model="dataKronologi" />
          </div>

        <vs-divider>Data Korban</vs-divider>

          <div class="flex">
            <vs-input
              v-validate="'required|min:3'"
              data-vv-validate-on="blur"
              label-placeholder="Nama Korban"
              name="Nama Korban"
              placeholder="Nama Korban"
              v-model="dataKorbanNama"
              class="w-1/2 mb-5 pr-5" 
            />
            <span class="text-danger text-sm">{{ errors.first('Nama Korban') }}</span>

            <div class="w-1/3 pr-5">
              <vs-input
                v-validate="'required|min:3'"
                data-vv-validate-on="blur"
                label-placeholder="NIK"
                name="NIK Korban"
                placeholder="NIK"
                v-model="dataKorbanNik"
                class="w-full mb-5" 
              />
              <span class="text-danger text-sm">{{ errors.first('NIK Korban') }}</span>
            </div>

            <vs-input
              v-validate="'required|numeric'"
              data-vv-validate-on="blur"
              label-placeholder="No. Telepon"
              name="Telepon Korban"
              placeholder="No. Telepon"
              v-model="dataKorbanTelepon"
              class="w-1/4 mb-5" 
            />
            <span class="text-danger text-sm">{{ errors.first('Telepon Korban') }}</span>
          </div>

          <div class="flex mb-5 items-center">
            <div class="w-1/3">
              <vs-input
                v-validate="'required|min:3'"
                data-vv-validate-on="blur"
                label-placeholder="Tempat Lahir"
                name="Tempat Lahir Korban"
                placeholder="Tempat Lahir"
                v-model="dataKorbanLahirTempat"
                class="w-full mb-5 pr-5" 
              />
              <span class="text-danger text-sm">{{ errors.first('Tempat Lahir Korban') }}</span>
            </div>
            
            <div class="w-1/4">
              <datepicker :language="languages[language]" format="dd MMMM yyyy" class="w-full pr-5" v-model="dataKorbanLahirTanggal" placeholder="Tanggal Lahir"></datepicker>
            </div>
            
            <div class="">
              <span>Jenis Kelamin</span>
              <ul class="flex mt-1">
                  <li class="mr-base">
                    <vs-radio color="success" v-model="dataKorbanJenisKelamin" vs-value="Laki-laki"  vs-name="KorbanJK">Laki-laki</vs-radio>
                  </li>
                  <li>
                    <vs-radio color="success" v-model="dataKorbanJenisKelamin" vs-value="Perempuan"  vs-name="KorbanJK">Perempuan</vs-radio>
                  </li>
              </ul>
            </div>
          </div>

          <div class="flex mb-5">
            <div class="w-1/3 flex">
              <vs-input
                v-validate="'required|numeric'"
                data-vv-validate-on="blur"
                label-placeholder="Usia (Tahun)"
                name="Usia Korban Tahun"
                placeholder="Usia (Tahun)"
                v-model="dataKorbanUsiaTahun"
                class="w-1/2 mb-5 pr-5" 
              />
              <span class="text-danger text-sm">{{ errors.first('Usia Korban Tahun') }}</span>
              <vs-input
                v-validate="'required|numeric'"
                data-vv-validate-on="blur"
                label-placeholder="Usia (Bulan)"
                name="Usia Korban Bulan"
                placeholder="Usia (Bulan)"
                v-model="dataKorbanUsiaBulan"
                class="w-1/2 mb-5 pr-5" 
              />
              <span class="text-danger text-sm">{{ errors.first('Usia Korban Bulan') }}</span>
            </div>
            
            <div class="w-1/3">
              <vs-select
                label="Agama"
                v-model="dataKorbanAgama"
                v-validate="'required'"
                class="w-full pr-5"
                @change="pilihKorbanAgama()"
                @click.prevent="changeKorbanAgama()"
                >
                  <vs-select-item value="0" text="Pilih"/>
                  <vs-select-item :key="itemx.id" :value="itemx.id" :text="itemx.name" v-for="itemx in listAgama"/>
              </vs-select>
            </div>
            
            <div class="w-1/3">
              <vs-input
                v-validate="'required|email'"
                data-vv-validate-on="blur"
                label-placeholder="Email"
                name="Email Korban"
                placeholder="Email"
                v-model="dataKorbanEmail"
                class="w-full mb-5" 
              />
              <span class="text-danger text-sm">{{ errors.first('Email Korban') }}</span>
            </div>
          </div>

          <div class="flex mb-5 items-center">
            <div class="w-1/3">
              <vs-input
                v-validate="'required|min:3'"
                data-vv-validate-on="blur"
                label-placeholder="Pendidikan"
                name="Pendidikan Korban"
                placeholder="Pendidikan"
                v-model="dataKorbanPendidikan"
                class="w-full mb-5 pr-5" 
              />
              <span class="text-danger text-sm">{{ errors.first('Pendidikan Korban') }}</span>
            </div>

            <div class="w-1/3">
              <vs-input
                v-validate="'required|min:3'"
                data-vv-validate-on="blur"
                label-placeholder="Pekerjaan"
                name="Pekerjaan Korban"
                placeholder="Pekerjaan"
                v-model="dataKorbanPekerjaan"
                class="w-full mb-5 pr-5" 
              />
              <span class="text-danger text-sm">{{ errors.first('Pekerjaan Korban') }}</span>
            </div>
            
            <div class="pr-5">
              <span>Status Perkawinan</span>
              <ul class="flex mt-1">
                  <li class="mr-2">
                    <vs-radio color="success" v-model="dataKorbanStatusPerkawinan" vs-value="Belum"  vs-name="KorbanStatusPerkawinan">Belum</vs-radio>
                  </li>
                  <li class="mr-2">
                    <vs-radio color="success" v-model="dataKorbanStatusPerkawinan" vs-value="Sudah"  vs-name="KorbanStatusPerkawinan">Sudah</vs-radio>
                  </li>
                  <li>
                    <vs-radio color="success" v-model="dataKorbanStatusPerkawinan" vs-value="Pernah"  vs-name="KorbanStatusPerkawinan">Pernah</vs-radio>
                  </li>
              </ul>
            </div>
            
            <div class="w-1/3">
              <span>Disabilitas</span>
              <ul class="flex mt-1">
                  <li class="mr-base">
                    <vs-radio color="success" v-model="dataKorbanStatusDisabilitas" vs-value="Ya"  vs-name="KorbanStatusDisabilitas">Ya</vs-radio>
                  </li>
                  <li>
                    <vs-radio color="danger" v-model="dataKorbanStatusDisabilitas" vs-value="Tidak"  vs-name="KorbanStatusDisabilitas">Tidak</vs-radio>
                  </li>
              </ul>
            </div>
          </div>

          <div class="flex">
            <div class="w-1/3 pr-5">
              <vs-select
              label="Kecamatan"
              v-model="dataKorbanKecamatan"
              v-validate="'required'"
              class="w-full mb-5"
              @change="mountKorbanKelurahan()"
              @click.prevent="changeKorbanKecamatan()"
              >
                  <vs-select-item value="0" text="Pilih"/>
                  <vs-select-item :key="itemx.id" :value="itemx.id" :text="itemx.name" v-for="itemx in listKecamatan"/>
              </vs-select>

              <vs-select
              label="Kelurahan"
              v-model="dataKorbanKelurahan"
              v-validate="'required'"
              class="w-full mb-5"
              @change="pilihKorbanKelurahan()"
              @click.prevent="changeKorbanKelurahan()"
              >
                  <vs-select-item value="0" text="Pilih"/>
                  <vs-select-item :key="itemx.id" :value="itemx.id" :text="itemx.name" v-for="itemx in listKorbanKelurahan"/>
              </vs-select>
            </div>

            <vs-textarea class="bg-white w-3/4" label="Alamat Korban" v-model="dataKorbanAlamat" />
          </div>

        <vs-divider>Data Pelaku</vs-divider>

          <div class="flex">
            <vs-input
              v-validate="'required|min:3'"
              data-vv-validate-on="blur"
              label-placeholder="Nama Pelaku"
              name="Nama Pelaku"
              placeholder="Nama Pelaku"
              v-model="dataPelakuNama"
              class="w-1/2 mb-5 pr-5" 
            />
            <span class="text-danger text-sm">{{ errors.first('Nama Pelaku') }}</span>

            <div class="w-1/3 pr-5">
              <vs-input
                v-validate="'required|min:3'"
                data-vv-validate-on="blur"
                label-placeholder="NIK"
                name="NIK Pelaku"
                placeholder="NIK"
                v-model="dataPelakuNik"
                class="w-full mb-5" 
              />
              <span class="text-danger text-sm">{{ errors.first('NIK Pelaku') }}</span>
            </div>

            <vs-input
              v-validate="'required|numeric'"
              data-vv-validate-on="blur"
              label-placeholder="No. Telepon"
              name="Telepon Pelaku"
              placeholder="No. Telepon"
              v-model="dataPelakuTelepon"
              class="w-1/4 mb-5" 
            />
            <span class="text-danger text-sm">{{ errors.first('Telepon Pelaku') }}</span>
          </div>

          <div class="flex mb-5 items-center">
            <div class="w-1/3">
              <vs-input
                v-validate="'required|min:3'"
                data-vv-validate-on="blur"
                label-placeholder="Tempat Lahir"
                name="Tempat Lahir Pelaku"
                placeholder="Tempat Lahir"
                v-model="dataPelakuLahirTempat"
                class="w-full mb-5 pr-5" 
              />
              <span class="text-danger text-sm">{{ errors.first('Tempat Lahir Pelaku') }}</span>
            </div>
            
            <div class="w-1/4">
              <datepicker :language="languages[language]" format="dd MMMM yyyy" class="w-full pr-5" v-model="dataPelakuLahirTanggal" placeholder="Tanggal Lahir"></datepicker>
            </div>
            
            <div class="">
              <span>Jenis Kelamin</span>
              <ul class="flex mt-1">
                  <li class="mr-base">
                    <vs-radio color="success" v-model="dataPelakuJenisKelamin" vs-value="Laki-laki"  vs-name="PelakuJK">Laki-laki</vs-radio>
                  </li>
                  <li>
                    <vs-radio color="success" v-model="dataPelakuJenisKelamin" vs-value="Perempuan"  vs-name="PelakuJK">Perempuan</vs-radio>
                  </li>
              </ul>
            </div>
          </div>

          <div class="flex mb-5">
            <div class="w-1/3 flex">
              <vs-input
                v-validate="'required|numeric'"
                data-vv-validate-on="blur"
                label-placeholder="Usia (Tahun)"
                name="Usia Pelaku Tahun"
                placeholder="Usia (Tahun)"
                v-model="dataPelakuUsiaTahun"
                class="w-1/2 mb-5 pr-5" 
              />
              <span class="text-danger text-sm">{{ errors.first('Usia Pelaku Tahun') }}</span>
              <vs-input
                v-validate="'required|numeric'"
                data-vv-validate-on="blur"
                label-placeholder="Usia (Bulan)"
                name="Usia Pelaku Bulan"
                placeholder="Usia (Bulan)"
                v-model="dataPelakuUsiaBulan"
                class="w-1/2 mb-5 pr-5" 
              />
              <span class="text-danger text-sm">{{ errors.first('Usia Pelaku Bulan') }}</span>
            </div>
            
            <div class="w-1/3">
              <vs-select
                label="Agama"
                v-model="dataPelakuAgama"
                v-validate="'required'"
                class="w-full pr-5"
                @change="pilihPelakuAgama()"
                @click.prevent="changePelakuAgama()"
                >
                  <vs-select-item value="0" text="Pilih"/>
                  <vs-select-item :key="itemx.id" :value="itemx.id" :text="itemx.name" v-for="itemx in listAgama"/>
              </vs-select>
            </div>
            
            <div class="w-1/3">
              <vs-input
                v-validate="'required|email'"
                data-vv-validate-on="blur"
                label-placeholder="Email"
                name="Email Pelaku"
                placeholder="Email"
                v-model="dataPelakuEmail"
                class="w-full mb-5" 
              />
              <span class="text-danger text-sm">{{ errors.first('Email Pelaku') }}</span>
            </div>
          </div>

          <div class="flex items-center">
            <div class="w-1/3">
              <vs-input
                v-validate="'required|min:3'"
                data-vv-validate-on="blur"
                label-placeholder="Pendidikan"
                name="Pendidikan Pelaku"
                placeholder="Pendidikan"
                v-model="dataPelakuPendidikan"
                class="w-full mb-5 pr-5" 
              />
              <span class="text-danger text-sm">{{ errors.first('Pendidikan Pelaku') }}</span>
            </div>

            <div class="w-1/3">
              <vs-input
                v-validate="'required|min:3'"
                data-vv-validate-on="blur"
                label-placeholder="Pekerjaan"
                name="Pekerjaan Pelaku"
                placeholder="Pekerjaan"
                v-model="dataPelakuPekerjaan"
                class="w-full mb-5 pr-5" 
              />
              <span class="text-danger text-sm">{{ errors.first('Pekerjaan Pelaku') }}</span>
            </div>
            
            <div class="pr-5">
              <span>Status Perkawinan</span>
              <ul class="flex mt-1">
                  <li class="mr-2">
                    <vs-radio color="success" v-model="dataPelakuStatusPerkawinan" vs-value="Belum"  vs-name="PelakuStatusPerkawinan">Belum</vs-radio>
                  </li>
                  <li class="mr-2">
                    <vs-radio color="success" v-model="dataPelakuStatusPerkawinan" vs-value="Sudah"  vs-name="PelakuStatusPerkawinan">Sudah</vs-radio>
                  </li>
                  <li>
                    <vs-radio color="success" v-model="dataPelakuStatusPerkawinan" vs-value="Pernah"  vs-name="PelakuStatusPerkawinan">Pernah</vs-radio>
                  </li>
              </ul>
            </div>
            
            <div class="w-1/3">
              <span>Disabilitas</span>
              <ul class="flex mt-1">
                  <li class="mr-base">
                    <vs-radio color="success" v-model="dataPelakuStatusDisabilitas" vs-value="Ya"  vs-name="PelakuStatusDisabilitas">Ya</vs-radio>
                  </li>
                  <li>
                    <vs-radio color="danger" v-model="dataPelakuStatusDisabilitas" vs-value="Tidak"  vs-name="PelakuStatusDisabilitas">Tidak</vs-radio>
                  </li>
              </ul>
            </div>
          </div>

          <div class="flex mb-5 items-center">
            <div class="pl-5">
              <span>Penghasilan</span>
              <ul class="flex mt-1">
                  <li class="mr-2">
                    <vs-radio color="success" v-model="dataPelakuPenghasilan" vs-value="< Rp. 500.000"  vs-name="PelakuPenghasilan">Kurang dari Rp. 500.000</vs-radio>
                  </li>
                  <li class="mr-2">
                    <vs-radio color="success" v-model="dataPelakuPenghasilan" vs-value="Rp. 500.000 - Rp. 2.000.000"  vs-name="PelakuPenghasilan">Rp. 500.000 - Rp. 2.000.000</vs-radio>
                  </li>
                  <li class="mr-2">
                    <vs-radio color="success" v-model="dataPelakuPenghasilan" vs-value="Rp. 2.000.000 - Rp. 5.000.000"  vs-name="PelakuPenghasilan">Rp. 2.000.000 - Rp. 5.000.000</vs-radio>
                  </li>
                  <li class="mr-2">
                    <vs-radio color="success" v-model="dataPelakuPenghasilan" vs-value="> Rp. 5.000.000"  vs-name="PelakuPenghasilan">Lebih dari Rp. 5.000.000</vs-radio>
                  </li>
              </ul>
            </div>

          </div>

          <div class="flex items-center">
            <div class="w-1/3 pr-5">

              <vs-select
              label="Hubungan Dengan Korban"
              v-model="dataPelakuHubunganKorban"
              v-validate="'required'"
              class="w-full"
              @change="pilihPelakuHubunganKorban()"
              @click.prevent="changePelakuHubunganKorban()"
              >
                <vs-select-item value="0" text="Pilih"/>
                <vs-select-item :key="itemx.id" :value="itemx.id" :text="itemx.name" v-for="itemx in listHubunganKorban"/>
              </vs-select>

            </div>

            <div class="w-3/4">
              <div class="flex mb-5">
                <vs-select
                label="Kecamatan"
                v-model="dataPelakuKecamatan"
                v-validate="'required'"
                class="w-1/2 pr-5"
                @change="mountPelakuKelurahan()"
                @click.prevent="changePelakuKecamatan()"
                >
                    <vs-select-item value="0" text="Pilih"/>
                    <vs-select-item :key="itemx.id" :value="itemx.id" :text="itemx.name" v-for="itemx in listKecamatan"/>
                </vs-select>

                <vs-select
                label="Kelurahan"
                v-model="dataPelakuKelurahan"
                v-validate="'required'"
                class="w-1/2"
                @change="pilihPelakuKelurahan()"
                @click.prevent="changePelakuKelurahan()"
                >
                    <vs-select-item value="0" text="Pilih"/>
                    <vs-select-item :key="itemx.id" :value="itemx.id" :text="itemx.name" v-for="itemx in listPelakuKelurahan"/>
                </vs-select>
              </div>
              <vs-textarea class="bg-white w-full" label="Alamat Pelaku" v-model="dataPelakuAlamat" />
            </div>
          </div>

      </component>
      <!--
      <div class="flex flex-wrap items-center p-6 justify-between" slot="footer">
        <vs-button v-if="filter != 'trash'" class="" :disabled="!isFormValid" @click="submitData" color="secondary">Update</vs-button>
        <vs-button type="border" color="danger" @click="closeUpdateView">Cancel</vs-button>
      </div>
      -->

      <vs-prompt
        color="danger"
        title="Konfirmasi"
        @accept="hapusLaporanAccept"
        :active.sync="hapusLaporan">
        <div class="con-exemple-prompt">
          Yakin ingin menghapus laporan {{ dataKorbanNama }}
        </div>
      </vs-prompt>

      <vs-prompt
        color="warning"
        title="Konfirmasi"
        @accept="restoreLaporanAccept"
        :active.sync="restoreLaporan">
        <div class="con-exemple-prompt">
          Yakin ingin me-restore laporan {{ dataKorbanNama }}
        </div>
      </vs-prompt>
    </vs-sidebar>
  </div>
</template>
<script>
import VuePerfectScrollbar  from 'vue-perfect-scrollbar'
import { mapGetters } from 'vuex'
import Datepicker from 'vuejs-datepicker';
import * as lang from 'vuejs-datepicker/src/locale';
export default{
  props: {
    isUpdateActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    VuePerfectScrollbar,
    Datepicker
  },
  data () {
    return {
      // Set
        language: "id",
        languages: lang,
        settings: {
          maxScrollbarLength: 60,
          wheelSpeed: 0.50
        },
        disabled: false,
      
      hapusLaporan: false,
      restoreLaporan: false,

      dataAuthor: null,

      // Init
        dataLaporansID: 0,
        dataRelation: null,
      
        listKecamatan: [],
        listHubunganKorban: [],

        dataKronologi: '',

        dataPelaporHubunganKorban: 0,
        dataPelaporHubunganKorbanNama: '',
        dataChangePelaporHubunganKorban: false,

        dataPelakuHubunganKorban: 0,
        dataPelakuHubunganKorbanNama: '',
        dataChangePelakuHubunganKorban: false,

        listLaporanJenis: [],
        dataLaporanJenis: 0,
        dataLaporanJenisNama: '',
        dataChangeLaporanJenis: false,

        listKekerasanBentuk: [],
        dataKekerasanBentuk: 0,
        dataKekerasanBentukNama: '',
        dataChangeKekerasanBentuk: false,

        listKekerasanModus: [],
        dataKekerasanModus: 0,
        dataKekerasanModusNama: '',
        dataChangeKekerasanModus: false,

        listKejadianTempat: [],
        dataKejadianTempat: 0,
        dataKejadianTempatNama: '',
        dataChangeKejadianTempat: false,

        dataKejadianTanggal: new Date(),

        dataPernahDilaporkan : 'Tidak',

        listTempatLapor: [],
        dataTempatLapor: 0,
        dataTempatLaporNama: '',
        dataChangeTempatLapor: false,

        listLayananDibutuhkan: [],
        dataLayananDibutuhkan: 0,
        dataLayananDibutuhkanNama: '',
        dataChangeLayananDibutuhkan: false,

        // Korban
        dataKorbanNama: '',
        dataKorbanTelepon: '',
        dataKorbanKecamatan: 0,
        dataKorbanKecamatanNama: '',
        dataKorbanChangeKecamatan: false,
        listKorbanKelurahan: [],
        dataKorbanKelurahan: 0,
        dataKorbanKelurahanNama: '',
        dataKorbanChangeKelurahan: false,
        dataKorbanAlamat: '',

        listAgama: [],
        dataKorbanAgama: 0,
        dataKorbanAgamaNama: '',
        dataChangeKorbanAgama: false,

        dataKorbanJenisKelamin: '',
        dataKorbanNik: '',
        dataKorbanUsiaTahun: '',
        dataKorbanUsiaBulan: '',
        dataKorbanLahirTempat: '',
        dataKorbanLahirTanggal: '',
        dataKorbanEmail: '',
        dataKorbanPendidikan: '',
        dataKorbanPekerjaan: '',
        dataKorbanStatusPerkawinan: '',
        dataKorbanStatusDisabilitas: '',

        // Pelaku
        dataPelakuNama: '',
        dataPelakuTelepon: '',
        dataPelakuKecamatan: 0,
        dataPelakuKecamatanNama: '',
        dataPelakuChangeKecamatan: false,
        listPelakuKelurahan: [],
        dataPelakuKelurahan: 0,
        dataPelakuKelurahanNama: '',
        dataPelakuChangeKelurahan: false,
        dataPelakuAlamat: '',

        dataPelakuJenisKelamin: '',
        dataPelakuNik: '',
        dataPelakuUsiaTahun: '',
        dataPelakuUsiaBulan: '',
        dataPelakuLahirTempat: '',
        dataPelakuLahirTanggal: '',
        
        dataPelakuAgama: 0,
        dataPelakuAgamaNama: '',
        dataChangePelakuAgama: false,

        dataPelakuEmail: '',
        dataPelakuPendidikan: '',
        dataPelakuPekerjaan: '',
        dataPelakuPenghasilan: '',
        dataPelakuStatusPerkawinan: '',
        dataPelakuStatusDisabilitas: '',

    }
  },
  watch: {
    isUpdateActive (value) {
      if (value) {
        if (Object.entries(this.data).length === 0) {
          this.disabled = false
          this.initValues()
          this.$validator.reset()
        } else {
          //this.disabled = true
          const { id, tasks_id, task_parent, author, relation,
          kekerasan_bentuk_id, 
          kekerasan_bentuk_name, 
          kekerasan_modus_id, 
          kekerasan_modus_name, 
          kejadian_tempat_id, 
          kejadian_tempat_name, 
          pernah_dilaporkan, 
          tempat_lapor_id, 
          tempat_lapor_name, 
          kronologi, 
          kejadian_tanggal, 
          korban_nama, 
          korban_telepon, 
          korban_kecamatan_id, 
          korban_kecamatan_name, 
          korban_kelurahan_id, 
          korban_kelurahan_name, 
          korban_alamat, 
          pelaku_nama, 
          pelaku_telepon, 
          pelaku_kecamatan_id, 
          pelaku_kecamatan_name, 
          pelaku_kelurahan_id, 
          pelaku_kelurahan_name, 
          pelaku_alamat,

          layanan_dibutuhkan_id,
          layanan_dibutuhkan_name,

          korban_jenis_kelamin,
          korban_nik,
          korban_usia_tahun,
          korban_usia_bulan,
          korban_lahir_tempat,
          korban_lahir_tanggal,
          korban_agama_id,
          korban_agama_name,
          korban_email,
          korban_pendidikan,
          korban_pekerjaan,
          korban_status_perkawinan,
          korban_status_disabilitas,
          pelaku_jenis_kelamin,
          pelaku_nik,
          pelaku_usia_tahun,
          pelaku_usia_bulan,
          pelaku_lahir_tempat,
          pelaku_lahir_tanggal,
          pelaku_agama_id,
          pelaku_agama_name,
          pelaku_email,
          pelaku_pendidikan,
          pelaku_pekerjaan,
          pelaku_penghasilan,
          pelaku_status_perkawinan,
          pelaku_status_disabilitas,

          pelapor_hubungan_korban_id,
          pelapor_hubungan_korban_name,
          pelaku_hubungan_korban_id,
          pelaku_hubungan_korban_name

          } = JSON.parse(JSON.stringify(this.data))

          if (task_parent != 0) {
            this.dataParent = task_parent
          } else {
            this.dataParent = id
          }
          this.dataLaporansID = tasks_id

          this.dataAuthor = author
          
          this.dataRelation = relation

          this.dataLaporanJenis = relation.laporan[0].taxonomy_id
          this.dataLaporanJenisNama = relation.laporan[0].name

          this.dataKekerasanBentuk = kekerasan_bentuk_id
          this.dataKekerasanBentukNama = kekerasan_bentuk_name

          this.dataKekerasanModus = kekerasan_modus_id
          this.dataKekerasanModusNama = kekerasan_modus_name

          this.dataKejadianTempat = kejadian_tempat_id
          this.dataKejadianTempatNama = kejadian_tempat_name
          
          this.dataPernahDilaporkan = pernah_dilaporkan
          this.dataTempatLapor = tempat_lapor_id
          this.dataTempatLaporNama = tempat_lapor_name

          this.dataLayananDibutuhkan = layanan_dibutuhkan_id
          this.dataLayananDibutuhkanNama = layanan_dibutuhkan_name

          this.dataKronologi = kronologi

          this.dataKejadianTanggal = kejadian_tanggal

          this.dataKorbanNama = korban_nama
          this.dataKorbanTelepon = korban_telepon
          this.dataKorbanKecamatan = korban_kecamatan_id
          this.dataKorbanKecamatanNama = korban_kecamatan_name
          this.dataKorbanKelurahan = korban_kelurahan_id
          this.dataKorbanKelurahanNama = korban_kelurahan_name
          this.dataKorbanAlamat = korban_alamat

          let configs = { headers: { 'Authorization': 'Bearer ' + this.user.api_token } }
          this.axios.get('/term-parent/'+this.dataKorbanKecamatan, configs)
          .then((response) => {
            let {data} = response.data
            this.listKorbanKelurahan = data
          })
          .catch((error) => {
            let data = error.data
            this.listKorbanKelurahan = data
          })

          this.dataPelakuNama = pelaku_nama
          this.dataPelakuTelepon = pelaku_telepon
          this.dataPelakuKecamatan = pelaku_kecamatan_id
          this.dataPelakuKecamatanNama = pelaku_kecamatan_name
          this.dataPelakuKelurahan = pelaku_kelurahan_id
          this.dataPelakuKelurahanNama = pelaku_kelurahan_name
          this.dataPelakuAlamat = pelaku_alamat

          this.axios.get('/term-parent/'+this.dataPelakuKecamatan, configs)
          .then((response) => {
            let {data} = response.data
            this.listPelakuKelurahan = data
          })
          .catch((error) => {
            let data = error.data
            this.listPelakuKelurahan = data
          })

          this.dataKorbanJenisKelamin = korban_jenis_kelamin
          this.dataKorbanNik = korban_nik
          this.dataKorbanUsiaTahun = korban_usia_tahun
          this.dataKorbanUsiaBulan = korban_usia_bulan
          this.dataKorbanLahirTempat = korban_lahir_tempat
          this.dataKorbanLahirTanggal = korban_lahir_tanggal
          this.dataKorbanAgama = korban_agama_id
          this.dataKorbanAgamaNama = korban_agama_name
          this.dataKorbanEmail = korban_email
          this.dataKorbanPendidikan = korban_pendidikan
          this.dataKorbanPekerjaan = korban_pekerjaan
          this.dataKorbanStatusPerkawinan = korban_status_perkawinan
          this.dataKorbanStatusDisabilitas = korban_status_disabilitas

          this.dataPelakuJenisKelamin = pelaku_jenis_kelamin
          this.dataPelakuNik = pelaku_nik
          this.dataPelakuUsiaTahun = pelaku_usia_tahun
          this.dataPelakuUsiaBulan = pelaku_usia_bulan
          this.dataPelakuLahirTempat = pelaku_lahir_tempat
          this.dataPelakuLahirTanggal = pelaku_lahir_tanggal
          this.dataPelakuAgama = pelaku_agama_id
          this.dataPelakuAgamaNama = pelaku_agama_name
          this.dataPelakuEmail = pelaku_email
          this.dataPelakuPendidikan = pelaku_pendidikan
          this.dataPelakuPekerjaan = pelaku_pekerjaan
          this.dataPelakuPenghasilan = pelaku_penghasilan
          this.dataPelakuStatusPerkawinan = pelaku_status_perkawinan
          this.dataPelakuStatusDisabilitas = pelaku_status_disabilitas

          this.dataPelaporHubunganKorban = pelapor_hubungan_korban_id
          this.dataPelaporHubunganKorbanNama = pelapor_hubungan_korban_name

          this.dataPelakuHubunganKorban = pelaku_hubungan_korban_id
          this.dataPelakuHubunganKorbanNama = pelaku_hubungan_korban_name
          
        }
      } else { return }
    }
  },
  computed: {
    scrollbarTag () { return this.$store.getters.scrollbarTag },
    ...mapGetters({
      user : 'auth/user',
    }),
    isFormValid () { 
      return !this.errors.any() && 
      // !this.disabled &&
      this.dataPelaporHubunganKorban != 0 &&
      this.dataPelaporHubunganKorbanNama != '' &&
          
      this.dataPelakuHubunganKorban != 0 &&
      this.dataPelakuHubunganKorbanNama != '' &&

      this.dataLaporanJenis != 0 &&
      this.dataLaporanJenisNama != '' &&

      this.dataKekerasanBentuk != 0 &&
      this.dataKekerasanBentukNama != '' &&
      
      this.dataKekerasanModus != 0 &&
      this.dataKekerasanModusNama != '' &&

      this.dataKejadianTempat != 0 &&
      this.dataKejadianTempatNama != '' &&

      this.dataTempatLapor != 0 &&
      this.dataTempatLaporNama != '' &&

      this.dataLayananDibutukan != 0 &&
      this.dataLayananDibutukanNama != '' &&

      this.dataKronologi != '' &&

      this.dataKorbanNama != '' &&
      this.dataKorbanTelepon != '' &&
      this.dataKorbanKecamatan != 0 &&
      this.dataKorbanKecamatanNama != '' &&
      this.dataKorbanKelurahan != 0 &&
      this.dataKorbanKelurahanNama != '' &&
      this.dataKorbanAlamat != '' &&


      

      this.dataPelakuNama != '' &&
      this.dataPelakuTelepon != '' &&
      this.dataPelakuKecamatan != 0 &&
      this.dataPelakuKecamatanNama != '' &&
      this.dataPelakuKelurahan != 0 &&
      this.dataPelakuKelurahanNama != '' &&
      this.dataPelakuAlamat != '' &&

      this.dataKorbanAgama != 0 &&
      this.dataKorbanAgamaNama != '' &&

      this.dataKorbanJenisKelamin != '' &&
      this.dataKorbanNik != '' &&
      this.dataKorbanUsiaTahun != '' &&
      this.dataKorbanUsiaBulan != '' &&
      this.dataKorbanLahirTempat != '' &&
      this.dataKorbanLahirTanggal != '' &&
      this.dataKorbanEmail != '' &&
      this.dataKorbanPendidikan != '' &&
      this.dataKorbanPekerjaan != '' &&
      this.dataKorbanStatusPerkawinan != '' &&
      this.dataKorbanStatusDisabilitas != '' &&


      this.dataPelakuJenisKelamin != '' &&
      this.dataPelakuNik != '' &&
      this.dataPelakuUsiaTahun != '' &&
      this.dataPelakuUsiaBulan != '' &&
      this.dataPelakuLahirTempat != '' &&
      this.dataPelakuLahirTanggal != '' &&
      
      this.dataPelakuAgama != 0 &&
      this.dataPelakuAgamaNama != '' &&

      this.dataPelakuEmail != '' &&
      this.dataPelakuPendidikan != '' &&
      this.dataPelakuPekerjaan != '' &&
      this.dataPelakuPenghasilan != '' &&
      this.dataPelakuStatusPerkawinan != '' &&
      this.dataPelakuStatusDisabilitas != ''

    },
    filter () {
      return this.$route.params.filter
    }
  },
  created () {
  },
  methods: {
    submitData () {
      this.$validator.validateAll().then(result => {
      if (result) {
          this.$vs.loading()
          let formData = new FormData()

          formData.set('title', this.dataKorbanNama+' - '+this.dataKorbanTelepon)
          formData.set('status', 'publish')
          formData.set('comment_status', 'open')
          formData.set('password', null)
          formData.set('parent', 0)
          formData.set('type', 'laporan')
          formData.set('comment_count', 0)

          formData.set('tasks_id', this.dataLaporansID)
          formData.set('id', this.dataLaporansID)

          let meta = {
            pelapor_hubungan_korban_id: this.dataPelaporHubunganKorban,
            pelapor_hubungan_korban_name: this.dataPelaporHubunganKorbanNama,

            pelaku_hubungan_korban_id: this.dataPelakuHubunganKorban,
            pelaku_hubungan_korban_name: this.dataPelakuHubunganKorbanNama,

            kekerasan_bentuk_id: this.dataKekerasanBentuk,
            kekerasan_bentuk_name: this.dataKekerasanBentukNama,

            kekerasan_modus_id: this.dataKekerasanModus,
            kekerasan_modus_name: this.dataKekerasanModusNama,

            kejadian_tempat_id: this.dataKejadianTempat,
            kejadian_tempat_name: this.dataKejadianTempatNama,
            
            pernah_dilaporkan: this.dataPernahDilaporkan,
            tempat_lapor_id: this.dataTempatLapor,
            tempat_lapor_name: this.dataTempatLaporNama,

            layanan_dibutuhkan_id: this.dataLayananDibutuhkan,
            layanan_dibutuhkan_name: this.dataLayananDibutuhkanNama,

            kronologi: this.dataKronologi,

            kejadian_tanggal: this.dataKejadianTanggal,

            korban_nama: this.dataKorbanNama,
            korban_telepon: this.dataKorbanTelepon,
            korban_kecamatan_id: this.dataKorbanKecamatan,
            korban_kecamatan_name: this.dataKorbanKecamatanNama,
            korban_kelurahan_id: this.dataKorbanKelurahan,
            korban_kelurahan_name: this.dataKorbanKelurahanNama,
            korban_alamat: this.dataKorbanAlamat,

            pelaku_nama: this.dataPelakuNama,
            pelaku_telepon: this.dataPelakuTelepon,
            pelaku_kecamatan_id: this.dataPelakuKecamatan,
            pelaku_kecamatan_name: this.dataPelakuKecamatanNama,
            pelaku_kelurahan_id: this.dataPelakuKelurahan,
            pelaku_kelurahan_name: this.dataPelakuKelurahanNama,
            pelaku_alamat: this.dataPelakuAlamat,

            // NULL

            // KORBAN
            korban_jenis_kelamin: this.dataKorbanJenisKelamin,
            korban_nik: this.dataKorbanNik,
            korban_usia_tahun: this.dataKorbanUsiaTahun,
            korban_usia_bulan: this.dataKorbanUsiaBulan,
            korban_lahir_tempat: this.dataKorbanLahirTempat,
            korban_lahir_tanggal: this.dataKorbanLahirTanggal,
            korban_agama_id: this.dataKorbanAgama,
            korban_agama_name: this.dataKorbanAgamaNama,
            korban_email: this.dataKorbanEmail,
            korban_pendidikan: this.dataKorbanPendidikan,
            korban_pekerjaan: this.dataKorbanPekerjaan,
            korban_status_perkawinan: this.dataKorbanStatusPerkawinan,
            korban_status_disabilitas: this.dataKorbanStatusDisabilitas,

            // PELAKU
            pelaku_jenis_kelamin: this.dataPelakuJenisKelamin,
            pelaku_nik: this.dataPelakuNik,
            pelaku_usia_tahun: this.dataPelakuUsiaTahun,
            pelaku_usia_bulan: this.dataPelakuBulan,
            pelaku_lahir_tempat: this.dataPelakuLahirTempat,
            pelaku_lahir_tanggal: this.dataPelakuLahirTanggal,
            pelaku_agama_id: this.dataPelakuAgama,
            pelaku_agama_name: this.dataPelakuAgamaNama,
            pelaku_email: this.dataPelakuEmail,
            pelaku_pendidikan: this.dataPelakuPendidikan,
            pelaku_pekerjaan: this.dataPelakuPekerjaan,
            pelaku_penghasilan: this.dataPelakuPenghasilan,
            pelaku_status_perkawinan: this.dataPelakuStatusPerkawinan,
            pelaku_status_disabilitas: this.dataPelakuStatusDisabilitas

          }
          formData.set('meta', JSON.stringify(meta))
               
          let relation =  {
            'laporan': [this.dataLaporanJenis]
          }
          formData.set('relation', JSON.stringify(relation))

          formData.set('urutan', 'desc')
          
          this.$store.dispatch('laporan/editUpdate', formData)
          .then((response) => {
            this.$vs.loading.close()
            this.$vs.notify({
              time : 4000,
              title: 'Berhasil',
              color  : response.status,
              text  : 'Laporan anda telah update',
            })
            /*
            this.initValues()
            this.closeUpdateView()
            this.initValues()
            */
          })
          .catch(error => {
            this.$vs.loading.close()
            let {data} = error.response
            this.$vs.notify({
                title: 'Error',
                text: data.message,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
            })
          })
      }
      })
    },
    hapusLaporanConfirm () {
      this.hapusLaporan = true
    },
    hapusLaporanAccept () {
      if (Object.entries(this.data).length > 0) {
        this.$vs.loading()
        const obj = {
          id : this.dataLaporansID,
          parent : this.dataParent
        }
        this.$store.dispatch('laporan/removeLaporan', obj)
        .then((response) => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Berhasil',
            color  : 'success',
            text  : response.message,
          })
          this.initValues()
          this.closeUpdateView()
          this.initValues()
        })
      }
      this.hapusLaporan = false
    },
    restoreLaporanConfirm () {
      this.restoreLaporan = true
    },
    restoreLaporanAccept () {
      if (Object.entries(this.data).length > 0) {
        this.$vs.loading()
        const obj = {
          id : this.dataLaporansID,
          parent : this.dataParent
        }
        this.$store.dispatch('laporan/restoreLaporan', obj)
        .then((response) => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Berhasil',
            color  : 'success',
            text  : response.message,
          })
          this.initValues()
          this.closeUpdateView()
          this.initValues()
        })
      }
      this.restoreLaporan = false
    },
    initValues() {
      this.dataAuthor = null
      this.dataLaporansID = 0
      this.dataRelation = null

      this.listKecamatan = []
            
      this.dataPelaporHubunganKorban = 0
      this.dataPelaporHubunganKorbanNama = ''
      this.dataChangePelaporHubunganKorban = false
      
      this.dataPelakuHubunganKorban = 0
      this.dataPelakuHubunganKorbanNama = ''
      this.dataChangePelakuHubunganKorban = false

      this.listLaporanJenis = []
      this.dataLaporanJenis = 0
      this.dataLaporanJenisNama = ''
      this.dataChangeLaporanJenis = false

      this.listKekerasanBentuk = []
      this.dataKekerasanBentuk = 0
      this.dataKekerasanBentukNama = ''
      this.dataChangeKekerasanBentuk = false

      this.listKekerasanModus = []
      this.dataKekerasanModus = 0
      this.dataKekerasanModusNama = ''
      this.dataChangeKekerasanModus = false

      this.listKejadianTempat = []
      this.dataKejadianTempat = 0
      this.dataKejadianTempatNama = ''
      this.dataChangeKejadianTempat = false

      this.listTempatLapor = []
      this.dataTempatLapor = 0
      this.dataTempatLaporNama = ''
      this.dataChangeTempatLapor = false

      this.listLayananDibutuhkan = []
      this.dataLayananDibutuhkan = 0
      this.dataLayananDibutuhkanNama = ''
      this.dataChangeLayananDibutuhkan = false
            
      this.dataPernahDilaporkan = 'Tidak'

      this.dataKronologi = ''

      this.dataKorbanNama = ''
      this.dataKorbanTelepon = ''
      this.dataKorbanKecamatan = 0
      this.dataKorbanKecamatanNama = ''
      this.dataKorbanChangeKecamatan = false
      this.listKorbanKelurahan = []
      this.dataKorbanKelurahan = 0
      this.dataKorbanKelurahanNama = ''
      this.dataKorbanChangeKelurahan = false
      this.dataKorbanAlamat = ''

      this.dataPelakuNama = ''
      this.dataPelakuTelepon = ''
      this.dataPelakuKecamatan = 0
      this.dataPelakuKecamatanNama = ''
      this.dataPelakuChangeKecamatan = false
      this.listPelakuKelurahan = []
      this.dataPelakuKelurahan = 0
      this.dataPelakuKelurahanNama = ''
      this.dataPelakuChangeKelurahan = false
      this.dataPelakuAlamat = ''
    },
    formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [year, month, day].join('-');
    },
    closeUpdateView() {
      this.initValues()
      this.$emit('closeUpdate')
      this.initValues()
    },
    mountKecamatan () {
      let configs = { headers: { 'Authorization': 'Bearer ' + this.user.api_token, }, }
      this.axios.get('/term-list/kecamatan', configs)
      .then((response) => {
          let {data} = response.data
          this.listKecamatan = data
      })
      .catch((error) => {
          let data = error.data
          this.listKecamatan = data
      }) 
    },
    
    // LOKASI KORBAN
      changeKorbanKecamatan () {
          this.dataKorbanChangeKecamatan = true
      },
      mountKorbanKelurahan () {
          if (this.dataKorbanChangeKecamatan) {
            const ItemIndex = this.listKecamatan.find((camat) => camat.id == this.dataKorbanKecamatan)
            this.dataKorbanKecamatanNama = ItemIndex.name
            let configs = { headers: { 'Authorization': 'Bearer ' + this.user.api_token } }
            this.axios.get('/term-parent/'+this.dataKorbanKecamatan, configs)
            .then((response) => {
                let {data} = response.data
                this.listKorbanKelurahan = data
            })
            .catch((error) => {
                let data = error.data
                this.listKorbanKelurahan = data
            }) 
          }
      },
      changeKorbanKelurahan () {
          this.dataKorbanChangeKelurahan = true
      },
      pilihKorbanKelurahan () {
          if (this.dataKorbanChangeKelurahan) {
            const ItemIndex = this.listKorbanKelurahan.find((lurah) => lurah.id == this.dataKorbanKelurahan)
            this.dataKorbanKelurahanNama = ItemIndex.name
          }
      },
    //
    // LOKASI PELAKU
      changePelakuKecamatan () {
          this.dataPelakuChangeKecamatan = true
      },
      mountPelakuKelurahan () {
          if (this.dataPelakuChangeKecamatan) {
            const ItemIndex = this.listKecamatan.find((camat) => camat.id == this.dataPelakuKecamatan)
            this.dataPelakuKecamatanNama = ItemIndex.name
            let configs = { headers: { 'Authorization': 'Bearer ' + this.user.api_token } }
            this.axios.get('/term-parent/'+this.dataPelakuKecamatan, configs)
            .then((response) => {
                let {data} = response.data
                this.listPelakuKelurahan = data
            })
            .catch((error) => {
                let data = error.data
                this.listPelakuKelurahan = data
            }) 
          }
      },
      changePelakuKelurahan () {
          this.dataPelakuChangeKelurahan = true
      },
      pilihPelakuKelurahan () {
          if (this.dataPelakuChangeKelurahan) {
            const ItemIndex = this.listPelakuKelurahan.find((lurah) => lurah.id == this.dataPelakuKelurahan)
            this.dataPelakuKelurahanNama = ItemIndex.name
          }
      },
    //
    
    // Jenis Laporan
      mountLaporanJenis () {
          let configs = { headers: { 'Authorization': 'Bearer ' + this.user.api_token, }, }
          this.axios.get('/term-list/laporan', configs)
          .then((response) => {
            let {data} = response.data
            this.listLaporanJenis = data
          })
          .catch((error) => {
            let data = error.data
            this.listLaporanJenis = data
          }) 
      },
      changeLaporanJenis () {
          this.dataChangeLaporanJenis = true
      },
      pilihLaporanJenis () {
          if (this.dataChangeLaporanJenis) {
            const ItemIndex = this.listlistLaporanJenis.find((bentuk) => bentuk.taxonomy_id == this.datalistLaporanJenis)
            this.datalistLaporanJenisNama = ItemIndex.name
          }
      },
    //
    
    // Kekerasan Bentuk
      mountKekerasanBentuk () {
          let configs = { headers: { 'Authorization': 'Bearer ' + this.user.api_token, }, }
          this.axios.get('/term-list/kekerasan_bentuk', configs)
          .then((response) => {
            let {data} = response.data
            this.listKekerasanBentuk = data
          })
          .catch((error) => {
            let data = error.data
            this.listKekerasanBentuk = data
          }) 
      },
      changeKekerasanBentuk () {
          this.dataChangeKekerasanBentuk = true
      },
      pilihKekerasanBentuk () {
          if (this.dataChangeKekerasanBentuk) {
            const ItemIndex = this.listKekerasanBentuk.find((bentuk) => bentuk.id == this.dataKekerasanBentuk)
            this.dataKekerasanBentukNama = ItemIndex.name
          }
      },
    //
    
    // Kekerasan Modus
      mountKekerasanModus () {
          let configs = { headers: { 'Authorization': 'Bearer ' + this.user.api_token, }, }
          this.axios.get('/term-list/kekerasan_modus', configs)
          .then((response) => {
            let {data} = response.data
            this.listKekerasanModus = data
          })
          .catch((error) => {
            let data = error.data
            this.listKekerasanModus = data
          }) 
      },
      changeKekerasanModus () {
          this.dataChangeKekerasanModus = true
      },
      pilihKekerasanModus () {
          if (this.dataChangeKekerasanModus) {
            const ItemIndex = this.listKekerasanModus.find((modus) => modus.id == this.dataKekerasanModus)
            this.dataKekerasanModusNama = ItemIndex.name
          }
      },
    //
    
    // Kejadian Tempat
      mountKejadianTempat () {
          let configs = { headers: { 'Authorization': 'Bearer ' + this.user.api_token, }, }
          this.axios.get('/term-list/kejadian_tempat', configs)
          .then((response) => {
            let {data} = response.data
            this.listKejadianTempat = data
          })
          .catch((error) => {
            let data = error.data
            this.listKejadianTempat = data
          }) 
      },
      changeKejadianTempat () {
          this.dataChangeKejadianTempat = true
      },
      pilihKejadianTempat () {
          if (this.dataChangeKejadianTempat) {
            const ItemIndex = this.listKejadianTempat.find((tempat) => tempat.id == this.dataKejadianTempat)
            this.dataKejadianTempatNama = ItemIndex.name
          }
      },
    //
    
    // Tempat Lapor
      mountTempatLapor () {
          let configs = { headers: { 'Authorization': 'Bearer ' + this.user.api_token, }, }
          this.axios.get('/term-list/tempat_lapor', configs)
          .then((response) => {
            let {data} = response.data
            this.listTempatLapor = data
          })
          .catch((error) => {
            let data = error.data
            this.listTempatLapor = data
          }) 
      },
      changeTempatLapor () {
          this.dataChangeTempatLapor = true
      },
      pilihTempatLapor () {
          if (this.dataChangeTempatLapor) {
            const ItemIndex = this.listTempatLapor.find((tempat) => tempat.id == this.dataTempatLapor)
            this.dataTempatLaporNama = ItemIndex.name
          }
      },
    //
    
    // Layanan Dibutuhkan
      mountLayananDibutuhkan () {
          let configs = { headers: { 'Authorization': 'Bearer ' + this.user.api_token, }, }
          this.axios.get('/term-list/layanan_dibutuhkan', configs)
          .then((response) => {
            let {data} = response.data
            this.listLayananDibutuhkan = data
          })
          .catch((error) => {
            let data = error.data
            this.listLayananDibutuhkan = data
          }) 
      },
      changeLayananDibutuhkan () {
          this.dataChangeLayananDibutuhkan = true
      },
      pilihLayananDibutuhkan () {
          if (this.dataChangeLayananDibutuhkan) {
            const ItemIndex = this.listLayananDibutuhkan.find((tempat) => tempat.id == this.dataLayananDibutuhkan)
            this.dataLayananDibutuhkanNama = ItemIndex.name
          }
      },
    //
    
    // Agama
      mountAgama () {
        let configs = { headers: { 'Authorization': 'Bearer ' + this.user.api_token, }, }
        this.axios.get('/term-list/agama', configs)
        .then((response) => {
          let {data} = response.data
          this.listAgama = data
        })
        .catch((error) => {
          let data = error.data
          this.listAgama = data
        }) 
      },
      changeKorbanAgama () {
        this.dataChangeKorbanAgama = true
      },
      pilihKorbanAgama () {
        if (this.dataChangeKorbanAgama) {
          const ItemIndex = this.listAgama.find((tempat) => tempat.id == this.dataKorbanAgama)
          this.dataKorbanAgamaNama = ItemIndex.name
        }
      },
      changePelakuAgama () {
        this.dataChangePelakuAgama = true
      },
      pilihPelakuAgama () {
        if (this.dataChangePelakuAgama) {
          const ItemIndex = this.listAgama.find((tempat) => tempat.id == this.dataPelakuAgama)
          this.dataPelakuAgamaNama = ItemIndex.name
        }
      },
    //

    // Hubungan Korban
      mountHubunganKorban () {
        let configs = { headers: { 'Authorization': 'Bearer ' + this.user.api_token, }, }
        this.axios.get('/term-list/hubungan_korban', configs)
        .then((response) => {
          let {data} = response.data
          this.listHubunganKorban = data
        })
        .catch((error) => {
          let data = error.data
          this.listHubunganKorban = data
        }) 
      },

      changePelaporHubunganKorban () {
        this.dataChangePelaporHubunganKorban = true
      },
      pilihPelaporHubunganKorban () {
        if (this.dataChangePelaporHubunganKorban) {
          const ItemIndex = this.listHubunganKorban.find((bentuk) => bentuk.id == this.dataPelaporHubunganKorban)
          this.dataPelaporHubunganKorbanNama = ItemIndex.name
        }
      },

      changePelakuHubunganKorban () {
        this.dataChangePelakuHubunganKorban = true
      },
      pilihPelakuHubunganKorban () {
        if (this.dataChangePelakuHubunganKorban) {
          const ItemIndex = this.listHubunganKorban.find((bentuk) => bentuk.id == this.dataPelakuHubunganKorban)
          this.dataPelakuHubunganKorbanNama = ItemIndex.name
        }
      },
    //
  },
  mounted () {
    this.mountKecamatan()
    this.mountLaporanJenis()
    this.mountKekerasanBentuk()
    this.mountKekerasanModus()
    this.mountKejadianTempat()
    this.mountTempatLapor()
    this.mountLayananDibutuhkan()
    this.mountAgama()
    this.mountHubunganKorban()
  }
}
</script>
<style lang="scss" scoped>
</style>